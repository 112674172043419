import './src/styles/global.css'
import 'tailwindcss/base.css'

import ReactDOM from 'react-dom'

export function replaceHydrateFunction() {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback)
  }
}
